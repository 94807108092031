import { Gear } from "@phosphor-icons/react";
import { useState } from "react";

import useMapStore from "../../../../hooks/useMapStore";

function Setting(props) {
  const [isOpen, setIsOpen] = useState(false);

  const positions = useMapStore((state) => state.positions);
  const searchMode = useMapStore((state) => state.searchMode);
  const centerPosition = useMapStore((state) => state.centerPosition);
  const radius = useMapStore((state) => state.radius);
  const maxHeight = useMapStore((state) => state.maxHeight);
  const startDuration = useMapStore((state) => state.startDuration);
  const endDuration = useMapStore((state) => state.endDuration);
  const isDurationFilter = useMapStore((state) => state.isDurationFilter);
  const isHeightFilter = useMapStore((state) => state.isHeightFilter);

  const downloadSetting = () => {
    const setting = {
      positions,
    };

    const startDate = isDurationFilter
      ? new Date(startDuration)
      : new Date(new Date(Date.now() - 86400000));

    const endDate = isDurationFilter
      ? new Date(endDuration)
      : new Date(new Date());

    setting.startDuration = startDate.toISOString().slice(0, 16);
    setting.endDuration = endDate.toISOString().slice(0, 16);

    if (isHeightFilter) {
      setting.maxHeight = maxHeight;
    }

    if (searchMode === "circle") {
      setting.radius = radius;
      setting.centerPosition = centerPosition;
    } else if (searchMode === "square") {
    }

    const settingBlob = new Blob([JSON.stringify(setting)], {
      type: "application/json",
    });
    const settingUrl = URL.createObjectURL(settingBlob);
    const settingLink = document.createElement("a");

    // Download the file as json file
    setting ? (settingLink.href = settingUrl) : (settingLink.href = settingUrl);
    settingLink.download = `Settings-${new Date()
      .toISOString()
      .slice(0, 16)}.json`;
    settingLink.click();

    setIsOpen(false);
  };

  return (
    <div className="relative inline-block ">
      <div className="flex items-center">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          className="p-0 m-0  "
        >
          <Gear size={16} aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right z-[60] absolute right-0 px-2 mt-2 w-32 rounded-md shadow-lg bg-custom3 ring-1 outline-none border-custom1 "
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1 text-xs " role="none">
            <button
              onClick={() => downloadSetting()}
              className="block py-2"
              role="menuitem"
            >
              Download Setting
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Setting;
