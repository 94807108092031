import React from "react";
import PositionInput from "../PositionInput";
import useMapStore from "../../../../hooks/useMapStore";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { defaultAddresses } from "../../../../constants";
import useDebounce from "../../../../hooks/useDebounce";

function AddressInput({ findLocationFromAddress }) {
  const address = useMapStore((state) => state.address);
  const setAddress = useMapStore((state) => state.setAddress);

  const [isFocused, setIsFocused] = React.useState(false);

  const handleAddressChange = (selectedAddress) => {
    setAddress(selectedAddress);
    setIsFocused(false);
  };

  // debounce address
  const debouncedAddress = useDebounce(address, 500);

  React.useEffect(() => {
    if (debouncedAddress) {
      findLocationFromAddress();
    }
  }, [debouncedAddress]);

  return (
    <div className="relative">
      <div
        className="flex gap-1"
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 500);
        }}
      >
        <PositionInput
          label="Address:"
          type="text"
          value={address}
          setValue={(e) => {
            setAddress(e.target.value);
          }}
        />
        <button
          className="p-0 m-0 text-white border-custom3 bg-custom2 border px-1 rounded"
          onClick={findLocationFromAddress}
        >
          <MagnifyingGlass size={16} weight="fill" />
        </button>
      </div>

      {isFocused && (
        <div className="absolute right-0 w-full bg-custom2 border-custom3 border top-8 text-xs text-white z-20 px-2">
          {defaultAddresses.map((defaultAddress, index) => (
            <p
              className="py-1 cursor-pointer hover:bg-custom3/40 "
              key={index}
              onClick={() => {
                handleAddressChange(defaultAddress);
              }}
            >
              {defaultAddress}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default AddressInput;
