import { Gear } from "@phosphor-icons/react";
import { useState } from "react";

import useMapStore from "../../../../hooks/useMapStore";

function Setting(props) {
  const [isOpen, setIsOpen] = useState(false);

  const flights = useMapStore((state) => state.flights);

  const downloadFlights = () => {
    const setting = {
      ...flights,
    };

    const settingBlob = new Blob([JSON.stringify(setting)], {
      type: "application/json",
    });
    const settingUrl = URL.createObjectURL(settingBlob);
    const settingLink = document.createElement("a");

    // Download the file as json file with name "flights-[date].json"
    setting ? (settingLink.href = settingUrl) : (settingLink.href = settingUrl);
    settingLink.download = `Flights-${new Date()
      .toISOString()
      .slice(0, 16)}.json`;
    settingLink.click();

    setIsOpen(false);
  };

  return (
    <div className="relative inline-block ">
      <div className="flex items-center">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          className="p-0 m-0  "
        >
          <Gear size={16} aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 px-2 mt-2 w-32 rounded-md shadow-lg bg-custom3 ring-1 outline-none border-custom1 "
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1 text-xs " role="none">
            <button
              onClick={() => downloadFlights()}
              className="block py-2"
              role="menuitem"
            >
              Download Flights
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Setting;
