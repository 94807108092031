import React from "react";
import {
  GoogleMap,
  Marker,
  OverlayView,
  Polyline,
} from "@react-google-maps/api";
import useMapStore from "../../../hooks/useMapStore";
import Airplane from "../../common/icons/Airplane";
import {
  findIntersectionPointsWithRect,
  formatDate,
  formatDateHMS,
  formatNumber,
  ktsToMph,
  positionsToPolyline,
} from "../../../utils";
import {
  AirplaneLanding,
  AirplaneTakeoff,
  ArrowRight,
  AirplaneInFlight,
} from "@phosphor-icons/react";

function SingleFlightMapView({ flight, zoom, center }) {
  const selectedFlight = useMapStore((state) => state.selectedFlight);
  const positions = useMapStore((state) => state.positions);
  const setSelectedFlight = useMapStore((state) => state.setSelectedFlight);

  const handleSelectFlight = async (flight) => {
    setSelectedFlight(flight);
  };

  const intersectionPoints = findIntersectionPointsWithRect(
    positions,
    flight.positions
  );

  if (!intersectionPoints) return null;

  if (intersectionPoints.intersections.length === 0) {
    return null;
  }

  return (
    <div
      className={`single-flight relative cursor-pointer w-full pb-14  ${
        selectedFlight?.ident === flight.ident
          ? "border-2 border-[#F8C023]"
          : "border border-custom3"
      } `}
      onClick={() => {
        handleSelectFlight(flight);
      }}
    >
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "300px" }}
        zoom={zoom * 0.9}
        center={center}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          mapTypeId: "hybrid",
        }}
      >
        {intersectionPoints.intersections?.length > 0 && (
          <>
            <Polyline
              path={positionsToPolyline(flight.positions, flight.last_position)}
              options={{
                strokeColor:
                  intersectionPoints.intersections[0].nearestPoint
                    .altitude_change === "D"
                    ? "#FF00CC"
                    : "#00FFFF",
                strokeWeight: 2,
                strokeOpacity: 1,
              }}
              onClick={() => {
                handleSelectFlight(flight);
              }}
            />

            <Marker
              position={{
                lat: center.lat,
                lng: center.lng,
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
              }}
            />
            <Marker
              position={{
                lat: intersectionPoints.nearestPoint.latitude,
                lng: intersectionPoints.nearestPoint.longitude,
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
              }}
            />
            <Marker
              position={{
                lat: intersectionPoints.intersections[0]?.intersection.latitude,
                lng: intersectionPoints.intersections[0]?.intersection
                  .longitude,
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              }}
            />
            <Marker
              position={{
                lat: intersectionPoints.intersections[1]?.intersection.latitude,
                lng: intersectionPoints.intersections[1]?.intersection
                  .longitude,
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
              }}
            />
          </>
        )}
        <Polyline
          path={[
            { lat: positions.lat1, lng: positions.lon1 },
            { lat: positions.lat1, lng: positions.lon2 },
            { lat: positions.lat2, lng: positions.lon2 },
            { lat: positions.lat2, lng: positions.lon1 },
            { lat: positions.lat1, lng: positions.lon1 },
          ]}
          options={{
            strokeColor: "#04FD04",
            strokeWeight: 5,
            strokeOpacity: 0.8,
          }}
        />
        <OverlayView
          position={{
            lat: flight?.last_position.latitude,
            lng: flight?.last_position.longitude,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <Airplane
            onClick={(event) => {
              event.stopPropagation(); // Stop event from propagating to the map
              handleSelectFlight(flight);
            }}
            style={{
              rotate: flight.last_position.heading + "deg",
            }}
            className="h-6 w-6 cursor-pointer"
          />
        </OverlayView>
      </GoogleMap>

      <div className="w-full absolute bottom-0 bg-custom1 text-white text-xs">
        <div className="flex justify-between items-center gap-4 p-1">
          <div className="flex justify-center items-center gap-2">
            {intersectionPoints.nearestPoint?.altitude_change === "D" ? (
              <AirplaneLanding
                className={`h-6 w-6 border rounded-full p-0.5  text-white`}
              />
            ) : intersectionPoints.nearestPoint?.altitude_change === "C" ? (
              <AirplaneTakeoff
                className={`h-6 w-6 border rounded-full p-0.5 text-white `}
              />
            ) : (
              <AirplaneInFlight
                className={`h-6 w-6 border rounded-full p-0.5 text-white`}
              />
            )}
            <div>
              <p className="text-custom4 font-medium text-sm text-center">
                {flight?.ident}{" "}
              </p>
              {flight?.aircraft_type && (
                <p className="text-white text-xs text-center w-max m-auto bg-custom3 py-0.3 px-1 rounded-sm ">
                  {flight?.aircraft_type}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center gap-1">
            <div className="text-center">
              <p>{flight?.origin?.city}</p>
            </div>
            <ArrowRight size={18} className="text-custom4" />
            <div className="text-center">
              <p>{flight?.destination?.city}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-1 border-t border-custom3/30 p-1 ">
          <p className="text-start">Nearest Point</p>
          <div className="text-end">
            <p>
              Altitude:{" "}
              {formatNumber(intersectionPoints.nearestPoint.altitude * 100)} ft,
            </p>
            <p>
              Speed:{" "}
              {formatNumber(
                ktsToMph(intersectionPoints.nearestPoint.groundspeed)
              )}{" "}
              mph
            </p>
            <p>
              Time: {formatDate(intersectionPoints.nearestPoint.timestamp)},{" "}
              {formatDateHMS(intersectionPoints.nearestPoint.timestamp)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleFlightMapView;
