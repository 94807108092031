import React from "react";
import MapInput from "./MapInput";
import FlightList from "./FlightList";
import MapViewToogleTabs from "./MapViewToogleTabs";
function LeftBar() {
  return (
    <div>
      <div className="w-[320px] bg-gray-500 h-screen p-3">
        <div className="flex flex-col h-full gap-3">
          <MapViewToogleTabs />
          <MapInput />
          <FlightList />
        </div>
      </div>
    </div>
  );
}

export default LeftBar;
