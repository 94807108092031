import { create } from "zustand";
import { defaultAddresses } from "../constants";

const now = new Date();
const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const timezoneOffset = new Date().getTimezoneOffset() * 60000;
const currentHour = now.getHours();

let endDuration;

if (currentHour < 7) {
  // If current time is before 7 AM, use current time as endDuration
  endDuration = new Date(now - timezoneOffset).toISOString().slice(0, 16);
} else {
  // If current time is 7 AM or later, set endDuration to 7 AM of the current day
  endDuration = new Date(startOfToday - timezoneOffset + 7 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 16);
}

let startDuration;

if (currentHour >= 23) {
  startDuration = new Date(
    new Date(Date.now() - 86400000 - new Date().getTimezoneOffset() * 60000)
  )
    .toISOString()
    .slice(0, 16);
} else {
  startDuration = new Date(startOfToday - timezoneOffset - 1 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 16);
}

const useMapStore = create((set) => ({
  flights: [],
  selectedFlight: null,
  positions: {
    lat1: 28.17210970976778,
    lon1: -82.50865363659598,
    lat2: 28.113446816195648,
    lon2: -82.44046183086733,
  },
  centerPosition: {
    lat: 28.142778,
    lon: -82.461111,
  },
  radius: 11000,
  searchStatus: {
    start_date: null,
    end_date: null,
    total: 0,
    index: 0,
  },
  mapMode: "all",
  selectedPoint: null, // Use for selecting point by clicking point on map
  isSelectingPoint: false, // Use for selecting point by clicking point on map
  selectingPointLabel: null, // Use for selecting point by clicking point on map
  searchMode: "circle", // Use for selecting point by clicking point on map
  isFiltering: false,

  isHeightFilter: false,
  maxHeight: 15000,

  isDurationFilter: false,
  endDuration: endDuration,
  startDuration: startDuration,

  address: defaultAddresses[0],

  setFlights: (flights) => set({ flights }),
  setPositions: (positions) => set({ positions }),
  setSelectedFlight: (selectedFlight) => set({ selectedFlight }),
  setSearchStatus: (searchStatus) => set({ searchStatus }),
  setMapMode: (mapMode) => set({ mapMode }),
  setIsSelectingPoint: (isSelectingPoint) => set({ isSelectingPoint }),
  setSelectedPoint: (selectedPoint) => set({ selectedPoint }),
  setSelectingPointLabel: (selectingPointLabel) => set({ selectingPointLabel }),
  setSearchMode: (searchMode) => set({ searchMode }),
  setCenterPosition: (centerPosition) => set({ centerPosition }),
  setRadius: (radius) => set({ radius }),

  setIsDurationFilter: (isDurationFilter) => set({ isDurationFilter }),
  setEndDuration: (endDuration) => set({ endDuration }),
  setStartDuration: (startDuration) => set({ startDuration }),

  setIsHeightFilter: (isHeightFilter) => set({ isHeightFilter }),
  setMaxHeight: (maxHeight) => set({ maxHeight }),

  setIsFiltering: (isFiltering) => set({ isFiltering }),

  setAddress: (address) => set({ address }),
}));

export default useMapStore;
