import React, { useEffect, useState } from "react";
import useMapStore from "../../../hooks/useMapStore";
import {
  AirplaneInFlight,
  AirplaneLanding,
  AirplaneTakeoff,
  CaretDown,
  CaretUp,
  Speedometer,
} from "@phosphor-icons/react";
import CountUp from "react-countup";
import Setting from "./flight_list/Setting";
import {
  findIntersectionPointsWithRect,
  formatDate,
  formatDateHMS,
  formatNumber,
  ktsToMph,
} from "../../../utils";

function FlightList() {
  const [isShow, setIsShow] = useState(true);

  const flights = useMapStore((state) => state.flights);
  const setSelectedFlight = useMapStore((state) => state.setSelectedFlight);
  const selectedFlight = useMapStore((state) => state.selectedFlight);
  const positions = useMapStore((state) => state.positions);

  const [oldFlightsCount, setOldFlightsCount] = useState(0);
  const [newFlightsCount, setNewFlightsCount] = useState(0);

  useEffect(() => {
    setOldFlightsCount(newFlightsCount);
    setNewFlightsCount(flights.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights]);

  return (
    <div
      className={`w-full text-sm bg-custom1 rounded-lg overflow-hidden shadow-lg  ${
        isShow && "flex-1 "
      } `}
    >
      <div className="flex bg-custom2 text-white justify-between items-center p-2">
        <p className="font-bold p-0 m-0">
          Flights{" "}
          <span className="text-xs font-normal ">
            (
            <CountUp
              end={flights.length}
              start={oldFlightsCount}
              duration={3}
            />
            )
          </span>
        </p>
        <div className="flex items-center gap-2">
          <Setting />
          <button className="p-0 m-0" onClick={() => setIsShow(!isShow)}>
            {isShow ? (
              <CaretDown size={16} weight="thin" />
            ) : (
              <CaretUp size={16} weight="thin" />
            )}
          </button>
        </div>
      </div>
      <div
        className={`h-[calc(100%-30px)]  ${isShow ? "block pb-2 " : "hidden"}`}
      >
        <div
          className={`pl-2 pr-1 py-2 h-full overflow-auto custom-scrollbar flex flex-col gap-1.5 `}
        >
          {flights.map((flight, index) => {
            const intersectionPoints = findIntersectionPointsWithRect(
              positions,
              flight.positions
            );

            if (!intersectionPoints) return null;

            if (intersectionPoints.intersections.length === 0) {
              return null;
            }

            return (
              <div
                key={index}
                className={`w-full flex justify-between items-center pl-1 pr-2 py-1 cursor-pointer transition-all ease-in-out
              ${
                selectedFlight?.ident === flight.ident
                  ? "bg-custom3 text-custom4"
                  : "bg-[#414141] text-white hover:bg-custom3"
              }
            `}
                onClick={() => setSelectedFlight(flight)}
              >
                <div className="flex justify-start items-center w-full gap-3">
                  {intersectionPoints.nearestPoint?.altitude_change === "D" ? (
                    <AirplaneLanding
                      className={`h-6 w-6 ml-2 ${
                        selectedFlight?.ident === flight.ident
                          ? "text-custom4"
                          : "text-white"
                      } `}
                    />
                  ) : intersectionPoints.nearestPoint?.altitude_change ===
                    "C" ? (
                    <AirplaneTakeoff
                      className={`h-6 w-6 ml-2 ${
                        selectedFlight?.ident === flight.ident
                          ? "text-custom4"
                          : "text-white"
                      } `}
                    />
                  ) : (
                    <AirplaneInFlight
                      className={`h-6 w-6 ml-2 ${
                        selectedFlight?.ident === flight.ident
                          ? "text-custom4"
                          : "text-white"
                      } `}
                    />
                  )}

                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <div className="flex justify-start items-end  gap-2">
                        <p className="font-medium text-sm p-0 m-0">
                          {flight?.ident}
                        </p>
                        <p className="text-xs text-nowrap p-0 m-0">
                          {flight?.aircraft_type}
                        </p>
                      </div>
                      <div>
                        <p className="text-end text-[11px] leading-none">
                          {formatDate(
                            intersectionPoints.nearestPoint.timestamp
                          )}
                          <br />
                          {formatDateHMS(
                            intersectionPoints.nearestPoint.timestamp
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="">
                      <span className="text-xs p-0 m-0">
                        {flight?.origin?.city} - {flight?.destination?.city}
                      </span>
                    </div>
                    <div className="flex items-center gap-4 mt-1  text-xs font-[400] ">
                      <p className="flex items-center gap-1">
                        <Speedometer className="h-4 w-4" />
                        <span className="text-xs font-[600]">
                          {formatNumber(
                            ktsToMph(
                              intersectionPoints.nearestPoint.groundspeed
                            )
                          )}{" "}
                          mph
                        </span>
                      </p>
                      <p className="flex items-center gap-1">
                        <AirplaneInFlight className="h-4 w-4 " />
                        <span className="text-xs font-[600]">
                          {formatNumber(
                            intersectionPoints.nearestPoint.altitude * 100
                          )}{" "}
                          ft
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {flights.length === 0 && (
            <div className="text-center text-sm text-white w-full">
              No flights available
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FlightList;
